export const environment = {
  globalAggregator: "https://global-aggregator-api.demo.xlog.net",
  globalCMS: "https://global-cms-api.demo.xlog.net",
  clientManagement: "https://client-management-api.demo.xlog.net",
  authManagementAuthentication: "https://auth-service-api.demo.xlog.net/auth",

  seaFreightService: "https://sea-freight-api.demo.xlog.net",
  transactionManagement: "https://transaction-service-api.demo.xlog.net",

  truckingService: "https://trucking-service-api.demo.xlog.net",

  generateInvoiceImageApi: "https://formimage.demo.xlog.net",
  base64:
    "https://fromimage-api.develop.xlog.asia/develop-getBase64FromImageUrl",
  base64Key: "1YpBVknVbR8EGvyjDsEfF2T0Nq6yurfoaNb4YA8D",
  googleMapsApiKey: "AIzaSyDnC23xX9YyLwYIbdx4nkegfRH6LTIrcP0",
  loginUrl: "https://login.demo.xlog.net",
  ssoUrl: "https://sso-middleware-api.demo.xlog.net",

  production: true,
  hmr: false,

  developerMap: true,
  xlogChat: "https://smalltalkui.chatframe.calicubilly.com",
  blackbox: "https://web.blackboxapp.demo.xlog.net",
  containerManagement: "https://container-inventory-api.develop.xlog.asia",
  cyWebUrl: "https://depot.demo.xlog.net",
};

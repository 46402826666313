import { Action } from '@ngrx/store';
import { AccreditationRequest } from 'app/core/models/accreditation-request.model'

export enum AccreditationActionTypes {
    SetAccreditation = '[AccreditationRequest] Set AccreditationRequest',
    RemoveAccreditation = '[AccreditationRequest] Remove AccerditationRequest' 
}

export class SetAccreditation implements Action {
    readonly type = AccreditationActionTypes.SetAccreditation;
    constructor(public payload: AccreditationRequest) {}

}

export class RemoveAccreditation implements Action {
    readonly type = AccreditationActionTypes.RemoveAccreditation;
    constructor() {}
}
export type AccreditationAction = SetAccreditation | RemoveAccreditation;
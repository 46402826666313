import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Booking } from 'app/core/models/booking.model';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  constructor(private http: HttpClient) { }

  /* Booking Request CRUD */

  createBookingRequest(companyId, payload): Observable<any> {
    return this.http.post<any>(
      `${environment.seaFreightService}/sfs/api/v1/bookingRequest/${companyId}`,
      payload
    );
  }

  getBookingRequestById(id): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/bookingRequest/${id}`
    );
  }

  getAllBookingRequest(): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/bookingRequest`
    );
  }

  updateBookingRequest(payload): Observable<any> {
    return this.http.put<any>(
      `${environment.globalAggregator}/sfs/api/v1/bookingRequest`,
      payload
    );
  }

  deleteBookingRequest(id): Observable<any> {
    return this.http.delete<any>(
      `${environment.seaFreightService}/sfs/api/v1/bookingRequest/${id}`
    );
  }

  searchRecommendation(payload: any): Observable<any> {
    return this.http.post<any>(
      `https://service-rates.calicubilly.com/osr/api/v1/serviceRate/inquire`,
      payload
    );
  }

  /* Container Rate CRUD */

  createContainerRate(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.seaFreightService}/sfs/api/v1/containerRate`,
      payload
    );
  }

  getContainerRateById(id): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/containerRate/${id}`
    );
  }

  getAllContainerRate(): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/containerRate`
    );
  }

  updateContainerRate(payload): Observable<any> {
    return this.http.put<any>(
      `${environment.globalAggregator}/sfs/api/v1/containerRate`,
      payload
    );
  }

  deleteContainerRate(id): Observable<any> {
    return this.http.delete<any>(
      `${environment.seaFreightService}/sfs/api/v1/containerRate/${id}`
    );
  }

  /* Document Rate CRUD */

  createDocument(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.seaFreightService}/sfs/api/v1/document`,
      payload
    );
  }

  getDocumentById(id): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/document/${id}`
    );
  }

  getAllDocument(): Observable<any> {
    return this.http.get<any>(`${environment.seaFreightService}/sfs/api/v1/document`);
  }

  updateDocument(payload): Observable<any> {
    return this.http.put<any>(
      `${environment.globalAggregator}/sfs/api/v1/document`,
      payload
    );
  }

  deleteDocument(id): Observable<any> {
    return this.http.delete<any>(
      `${environment.seaFreightService}/sfs/api/v1/document/${id}`
    );
  }

  /* Service Rate CRUD */

  createServiceRate(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.seaFreightService}/sfs/api/v1/serviceRate`,
      payload
    );
  }

  getServiceRateById(id): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/serviceRate/${id}`
    );
  }

  getAllServiceRate(): Observable<any> {
    return this.http.get<any>(`${environment.seaFreightService}/sfs/api/v1/serviceRate`);
  }

  updateServiceRate(payload): Observable<any> {
    return this.http.put<any>(
      `${environment.globalAggregator}/sfs/api/v1/serviceRate`,
      payload
    );
  }

  deleteServiceRate(id): Observable<any> {
    return this.http.delete<any>(
      `${environment.seaFreightService}/sfs/api/v1/serviceRate/${id}`
    );
  }

  /* Service Rate Group CRUD */

  createServiceRateGroup(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.seaFreightService}/sfs/api/v1/serviceRateGroup`,
      payload
    );
  }

  getServiceRateGroupById(id): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/serviceRateGroup/${id}`
    );
  }

  getAllServiceRateGroup(): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/serviceRateGroup`
    );
  }

  updateServiceRateGroup(payload): Observable<any> {
    return this.http.put<any>(
      `${environment.globalAggregator}/sfs/api/v1/serviceRateGroup`,
      payload
    );
  }

  deleteServiceRateGroup(id): Observable<any> {
    return this.http.delete<any>(
      `${environment.seaFreightService}/sfs/api/v1/serviceRateGroup/${id}`
    );
  }

  /* Service Ticket CRUD */

  createSetviceTicket(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.seaFreightService}/sfs/api/v1/setviceTicket`,
      payload
    );
  }

  getSetviceTicketById(id): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/setviceTicket/${id}`
    );
  }

  getAllSetviceTicket(): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/setviceTicket`
    );
  }

  updateSetviceTicket(payload): Observable<any> {
    return this.http.put<any>(
      `${environment.globalAggregator}/sfs/api/v1/setviceTicket`,
      payload
    );
  }

  deleteSetviceTicket(id): Observable<any> {
    return this.http.delete<any>(
      `${environment.seaFreightService}/sfs/api/v1/setviceTicket/${id}`
    );
  }

  /* Surcharge CRUD */

  createSurcharge(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.seaFreightService}/sfs/api/v1/surcharge`,
      payload
    );
  }

  getSurchargeById(id): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/surcharge/${id}`
    );
  }

  getAllSurcharge(): Observable<any> {
    return this.http.get<any>(`${environment.seaFreightService}/sfs/api/v1/surcharge`);
  }

  updateSurcharge(payload): Observable<any> {
    return this.http.put<any>(
      `${environment.globalAggregator}/sfs/api/v1/surcharge`,
      payload
    );
  }

  deleteSurcharge(id): Observable<any> {
    return this.http.delete<any>(
      `${environment.seaFreightService}/sfs/api/v1/surcharge/${id}`
    );
  }

  /* Transaction Log CRUD */

  createTransactionLog(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.seaFreightService}/sfs/api/v1/transactionLog`,
      payload
    );
  }

  getTransactionLogById(id): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/transactionLog/${id}`
    );
  }

  getAllTransactionLog(): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/transactionLog`
    );
  }

  updateTransactionLog(payload): Observable<any> {
    return this.http.put<any>(
      `${environment.seaFreightService}/sfs/api/v1/transactionLog`,
      payload
    );
  }

  /* Health Check CRUD */
  getAllHealthCheck(): Observable<any> {
    return this.http.get<any>(`${environment.seaFreightService}/sfs/api/v1/healthcheck`);
  }

  /* Health Check CRUD */
  getPorts(): Observable<any> {
    return this.http.get<any>(`${environment.globalCMS}/global/cms/api/v1/port`);
  }

  /* Get Commodities */
  getAllCommodities(): Observable<any> {
    return this.http.get<any>(`${environment.globalAggregator}/g/api/v1/commodity`);
  }

  /* Get guest shipper */
  getAllGuestShipperByCompanyId(id): Observable<any> {
    return this.http.get<any>(`${environment.seaFreightService}/sfs/api/v1/guest/${id}/all`);
  }

  getAllGuestShipperByCompanyIdAndType(id, type): Observable<any> {
    return this.http.get<any>(`${environment.seaFreightService}/sfs/api/v1/guest/${id}/guests/${type}`);
  }

  getAllGuestShipperById(id): Observable<any> {
    return this.http.get<any>(`${environment.seaFreightService}/sfs/api/v1/guest/${id}`);
  }

  addGuestShipper(payload): Observable<any> {
    return this.http.post<any>(`${environment.seaFreightService}/sfs/api/v1/guest`, payload);
  }

  /* Get packaging types */
  getPackagingTypes(): Observable<any> {
    return this.http.get<any>(`${environment.globalAggregator}/g/api/v1/packaging-type`);
  }

  /* Get load types */
  getLoadTypes(): Observable<any> {
    return this.http.get<any>(`${environment.globalAggregator}/g/api/v1/load-type`);
  }

  /* Get container types*/
  getContainerTypes(): Observable<any> {
    return this.http.get<any>(`${environment.globalAggregator}/g/api/v1/container-type`);
  }

  /* Get container types*/
  getContainerSizesByType(id): Observable<any> {
    return this.http.get<any>(`${environment.globalAggregator}/g/api/v1/container-type/${id}/sizes`);
  }

  /* Get Shipment types*/
  getShipmentTypes(): Observable<any> {
    return this.http.get<any>(`${environment.seaFreightService}/sfs/api/v1/utilities/shipmentTypes`);
  }

  /* Get container types*/
  getServiceTypes(id): Observable<any> {
    return this.http.get<any>(`${environment.globalAggregator}/g/api/v1/booking/services/${id}`);
  }

  getDashboardScoreCards(shipperId, query): Observable<any> {
    let params = new HttpParams()
      .set('id', shipperId)
      .set('column', query)

    return this.http.get<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/dashboard-score-card`,
      { params: params }
    );
  }

  getPostalCode(cityId): Observable<any> {
    return this.http.get<any>(`${environment.truckingService}/oat/api/v1/GetPostalCodes?cityId=${cityId}`)
  }

  outboundBookingCancellation(reservationId: string, payload, serviceTicketId, action) {
    return this.http.post<any>(`${environment.seaFreightService}/sfs/api/v1/sea-freight/${reservationId}/${serviceTicketId}/${action}-booking-cancellation`, payload);
  }

  getCountryList(name = ''): Observable<any> {
    return this.http.get<any>(
      `${environment.globalCMS}/global/cms/api/v2/country/filter?countryName=${name}`
    );
  }

  getStateList(id, name = ''): Observable<any> {
    return this.http.get<any>(
      `${environment.globalCMS}/global/cms/api/v2/country/${id}/states?stateName=${name}`
    );
  }

  getCityList(id, name = ''): Observable<any> {
    return this.http.get<any>(
      `${environment.globalCMS}/global/cms/api/v2/state/${id}/cities?cityName=${name}`
    );
  }
}

import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
  {
    id: "applications",
    title: "Applications",
    type: "group",
    icon: "apps",
    children: [
      // {
      //   id: 'dashboard',
      //   title: 'Dashboard',
      //   translate: '',
      //   type: 'item',
      //   icon: 'xlog-dashboard',
      //   url: '/main/dashboard',
      // },
      {
        id: "profile",
        title: "Profile",
        translate: "",
        type: "collapsable",
        icon: "xlog-profile",
        children: [
          {
            id: "company-profile",
            title: "Company Profile",
            type: "item",
            url: "/main/profile/company-profile",
          },
          {
            id: "my-profile",
            title: "My Profile",
            type: "item",
            url: "/main/profile/my-profile",
          },
        ],
      },
      {
        id: "transaction-management",
        title: "Transaction Management",
        translate: "",
        type: "collapsable",
        icon: "xlog-transaction-management",
        url: "/main/transactions",
        children: [
          {
            id: "service-request",
            title: "Service Request",
            type: "item",
            url: "/main/transaction-management/service-request",
          },
          // {
          //   id: 'service-ticket-trucking',
          //   title: 'Service Request Trucking',
          //   type: 'item',
          //   url: '/main/transaction-management/service-ticket-trucking',
          // },
        ],
      },
      {
        id: "vessel-schedule",
        title: "Vessel & Schedule",
        translate: "",
        type: "collapsable",
        icon: "xlog-schedules",
        children: [
          {
            id: "vessel-lines",
            title: "Lines",
            type: "item",
            url: "/main/vessel-schedule/lines",
          },
          {
            id: "vessels",
            title: "Vessels",
            type: "item",
            url: "/main/vessel-schedule/vessels",
          },
        ],
      },
      {
        id: "service-management",
        title: "Service & Rates",
        translate: "",
        type: "collapsable",
        icon: "xlog-service-rates",
        children: [
          {
            id: "sea-freight",
            title: "Sea Freight",
            type: "item",
            url: "/main/service-management",
          },
        ],
      },
      {
        id: "container-management",
        title: "Container Management",
        translate: "",
        type: "collapsable",
        icon: "xlog-container-management",
        children: [
          {
            id: "container-management-list",
            title: "Containers",
            type: "item",
            url: "/main/container-management/containers",
          },
          // {
          //   id: "slot-booking",
          //   title: "Slot Booking",
          //   type: "item",
          //   url: "/main/container-management/slot-booking",
          // },
        ],
      },
      {
        id: "finance-management",
        title: "Finance Management",
        translate: "",
        type: "collapsable",
        icon: "xlog-billing-management",
        children: [
          {
            id: "payable-receivable",
            title: "Payables & Receivables",
            type: "item",
            url: "/main/finance-management/payable-receivable",
          },
        ],
      },
      {
        id: "access-management",
        title: "Access Management",
        translate: "",
        type: "collapsable",
        icon: "xlog-access-management",
        children: [
          {
            id: "user",
            title: "User",
            type: "item",
            url: "/main/access-management/user",
          },
          {
            id: "group",
            title: "Group",
            type: "item",
            url: "/main/access-management/group",
          },
        ],
      },
      {
        id: "accreditation",
        title: "Accreditation",
        translate: "",
        type: "collapsable",
        icon: "xlog-accreditation",
        children: [
          {
            id: "accredited",
            title: "Accredited",
            type: "collapsable",
            children: [
              {
                id: "customer",
                title: "Customer",
                type: "item",
                url: "main/accreditation/accredited/customer",
              },
              {
                id: "shippingAgency",
                title: "Shipping Agency",
                type: "item",
                url: "main/accreditation/accredited/shipping-agency",
              },
              {
                id: "trucking",
                title: "Trucking",
                type: "item",
                url: "main/accreditation/accredited/trucking",
              },
            ],
          },
        ],
      },

      {
        id: "settings",
        title: "Settings",
        translate: "",
        type: "collapsable",
        icon: "xlog-settings",
        children: [
          {
            id: "contacts",
            title: "Contacts and Preferred provider",
            type: "item",
            url: "/main/settings/contacts",
          },
          // {
          //   id: 'document-template',
          //   title: 'Document Template',
          //   type: 'item',
          //   url: '/main/settings/document', //no component yet
          // },
          // {
          //   id: 'email-template',
          //   title: 'Email Template',
          //   type: 'item',
          //   url: '/main/settings/email', //no component yet
          // },
        ],
      },
      {
        id: "other-facilities",
        title: "Other Facilities",
        translate: "",
        type: "collapsable",
        icon: "xlog-billing-management",
        children: [
          {
            id: "facilities-loan",
            title: "Loan",
            type: "item",
            url: "/main/other-facilities/loan",
          },
          {
            id: "facilities-insurance",
            title: "Insurance",
            type: "item",
            url: "/main/other-facilities/insurance",
          },
        ],
      },
    ],
  },
];

export const navigationKYC: FuseNavigation[] = [
  {
    id: "applications",
    title: "Applications",
    type: "group",
    icon: "apps",
    children: [
      {
        id: "profile",
        title: "Profile",
        translate: "",
        type: "collapsable",
        icon: "xlog-profile",
        children: [
          {
            id: "company-profile",
            title: "Company Profile",
            type: "item",
            url: "/main/profile/company-profile",
          },
          {
            id: "my-profile",
            title: "My Profile",
            type: "item",
            url: "/main/profile/my-profile",
          },
        ],
      },
    ],
  },
];

import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { RootState, selectUser } from "app/core/store";
import { Subject } from "rxjs";
import { take, takeUntil, throwIfEmpty } from "rxjs/operators";
import { InfoNotifComponent } from "../../pop-up/info-notif/info-notif.component";
import { PopUpComponent } from "../../pop-up/pop-up.component";

@Component({
  selector: "app-kyc-notification",
  templateUrl: "./kyc-notification.component.html",
  styleUrls: ["./kyc-notification.component.scss"],
})
export class KycNotificationComponent implements OnInit {
  private _unsubscribeAll: Subject<any>;

  private _kysStatus: any;
  private _checkOld: any;
  private _isMasterUser: any;
  notApprove: boolean = false;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private store: Store<RootState>
  ) {
    this._unsubscribeAll = new Subject();

    this.store
      .pipe(select(selectUser))
      .pipe(take(1))
      .subscribe(
        (res) => {
          if (res) {
            this._kysStatus = res.kyc.kycStatusCode;
            this._checkOld = res.company.status;
            this._isMasterUser = res.user.isMasterUser;
          }
        },
        (err) => {
          console.warn(err);
        }
      );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.initializeAccount();
    }, 500);
  }

  initializeAccount() {
    this.notApprove = this.checkKYCStatus();
    if (this.notApprove) {
      this.masterUser();
    } else {
      this.router.navigate([`/main/transaction-management`], { replaceUrl: true });
    }
  }

  checkKYCStatus() {
    if (this._kysStatus != "APP") return true;
  }

  masterUser() {
    if (this._isMasterUser) {
      this.checkOldNew();
    } else {
      this.isNotMaster();
    }
  }

  isNotMaster() {
    if (this._checkOld) {
      this.router.navigate([`/main/transaction-management`], { replaceUrl: true });
    } else {
      this.getRejectRouting();
    }
  }

  checkOldNew() {
    if (this._kysStatus === "REJ") {
      this.getRejectRouting()
    } else {
      this.getModal()
    }

  }

  getModal() {
    if (this._checkOld) {
      this.getOldModal();
      return
    }
    this.getNewModal();
  }

  getRejectRouting() {
    this.router.navigate([`main/profile/company-profile`], { replaceUrl: true });
  }

  getOldModal() {
    let data = {
      description: `<p class="text-left"> In order to ensure a safe and reliable community,</p>
      <p class="text-left"> Verify this account by completing our Know-Your-Customer (KYC) requirements under</p>
      <p class="text-left"> Company Profile > Additional Information.</p>`,
      buttonTitle: "Manage KYC",
      buttonAction: "proceed",
      buttonClose: true,
      title: "Update Your Account",
    };

    const kycNotif = this.modalShow(data);

    kycNotif.afterClosed().subscribe((data) => {
      if (data) {
        this.router.navigate([`main/profile/company-profile`], { replaceUrl: true });
        return;
      }
      this.router.navigate([`/main/transaction-management`], { replaceUrl: true });
    });
  }

  getNewModal() {
    let data = {
      description: `<p class="text-left">In order to ensure a safe and reliable community,</p>
      <p  class="text-left"> Please verify your account by completing our Know-Your-Customer (KYC) requirements. </p>
      <p  class="text-left">Once approved, you will be able to enjoy the rest of the features of the platform.</p>`,
      buttonTitle: "Manage KYC",
      buttonAction: "proceed",
      buttonClose: true,
      title: null,
    };
    const kycNotif = this.modalShow(data);
    kycNotif.afterClosed().subscribe((data) => {
      this.router.navigate([`main/profile/company-profile`], { replaceUrl: true });
    });
  }

  modalShow(data) {
    return this.dialog.open(InfoNotifComponent, {
      panelClass: "info-notif-modal",
      data: {
        title: data.title,
        description: data.description,
        closeButton: data.buttonClose,
        button: {
          title: data.buttonTitle,
          action: data.buttonAction,
        },
      },
    });
  }
}

import { AccreditationAction, AccreditationActionTypes } from './accreditation-request.action'
import { AccreditationRequest } from 'app/core/models/accreditation-request.model'

export const initialState: AccreditationRequest = null;

export function reducer(state = initialState, action:AccreditationAction): AccreditationRequest {
    switch(action.type) {
        case AccreditationActionTypes.SetAccreditation:
            state = action.payload;
            return state;
        case AccreditationActionTypes.RemoveAccreditation:
            state = initialState;
            return state;
        default:
            return state;
    }
}
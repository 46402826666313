import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil, take } from "rxjs/internal/operators";

import { FuseConfigService } from "@fuse/services/config.service";
import { fuseAnimations } from "@fuse/animations";
import { MatDialog } from "@angular/material/dialog";
import { PopUpComponent } from "../pop-up/pop-up.component";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { DataService } from "app/services/data.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { FuseUtils } from "@fuse/utils";
import { SnackbarComponent } from "@fuse/components/snackbar/snackbar.component";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  resetPasswordForm: FormGroup;

  //Modal
  dialogRef: any;

  // Private
  private _unsubscribeAll: Subject<any>;
  config: any;
  Currenthide = true;
  hide = true;
  hideConfirm = true;
  passwordStrength: boolean = false;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private _fuseSidebarService: FuseSidebarService,
    private data: DataService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _snackBar: MatSnackBar
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
      customScrollbars: false,
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.resetPasswordForm = this._formBuilder.group({
      currentPassword: ["", [Validators.required, Validators.minLength(8)]],
      password: ["", [Validators.required, Validators.minLength(8)]],
      passwordConfirm: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          confirmPasswordValidator,
        ],
      ],
    });

    // Update the validity of the 'passwordConfirm' field
    // when the 'password' field changes
    this.resetPasswordForm
      .get("password")
      .valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.resetPasswordForm.get("passwordConfirm").updateValueAndValidity();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  onStrengthChanged(strength: number) {
    switch (strength) {
      case 100:
        this.passwordStrength = true;

        break;

      default:
        this.passwordStrength = false;
    }
  }
  submit(): void {
    const regData = {
      OldPassword: this.resetPasswordForm.controls.currentPassword.value,
      Password: this.resetPasswordForm.controls.password.value,
      PasswordConfirmation:
        this.resetPasswordForm.controls.passwordConfirm.value,
    };
    this._fuseSplashScreenService.show();

    this.data
      .changePassword(regData)
      .pipe(take(1))
      .subscribe(
        (content) => {
          if (content) {
            // const data = {
            //   data: "success",
            //   title: "SUCCESS !",
            //   description: content.message,
            // };
            // this.openPopUp(data);
            this._snackBar.openFromComponent(
              SnackbarComponent,
              FuseUtils.showSnackbarConfig(
                "Success !",
                "success",
                content.message
              )
            );
            this.resetPasswordForm.reset();
            this._fuseSidebarService.getSidebar("changePassword").toggleOpen();
            this._fuseSplashScreenService.hide();
          }
        },
        (error) => {
          const data = {
            data: "error",
            title: "ERROR !",
            description: this.errorHandler(error),
          };
          this.openPopUp(data);
          this._fuseSplashScreenService.hide();
        }
      );
  }

  openPopUp(data): void {
    this.dialogRef = this.dialog.open(PopUpComponent, {
      panelClass: "contact-form-dialog",
      data: {
        data: data.data,
        title: data.title,
        description: data.description,
      },
    });

    this.dialogRef.afterClosed().subscribe((response) => {
      if (!response) {
        return;
      }
      const actionType: string = response[0];
      const formData: FormGroup = response[1];
      switch (actionType) {
        /**
         * Save
         */
        case "save":
          // Saving for API

          break;
        /**
         * Delete
         */
        case "delete":
          break;
      }
    });
  }

  errorHandler(data: any) {
    return FuseUtils.handleError(data);
  }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get("password");
  const passwordConfirm = control.parent.get("passwordConfirm");

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === "") {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};

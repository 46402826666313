import { ServiceRequestActions, ServiceRequestActionTypes } from './service.request.action';
import { Booking } from '../../models/booking.model';


export const initialState: any = null;
export function reducer(state = initialState, action: ServiceRequestActions): any {
  switch (action.type) {
    case ServiceRequestActionTypes.SetServiceRequest:
      state = action.payload;
      return state;
    case ServiceRequestActionTypes.RemoveServiceRequest:
      state = initialState;
      return state;
    default:
      return state;
  }
}

import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import { select, Store } from "@ngrx/store";
import { RootState, selectUser } from "app/core/store";
import { SharedServiceService } from "app/services/shared-service.service";
import { environment } from "environments/environment";
import { filter, startWith, take } from "rxjs/operators";

@Component({
  selector: "app-blackbox-component",
  templateUrl: "./blackbox-component.component.html",
  styleUrls: ["./blackbox-component.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class BlackboxComponentComponent implements OnInit {
  isShowChatHead: boolean = true;
  isShowChatBody: boolean = false;
  isShowChat: boolean = true;

  iframeUrl: any;
  companyId: string;
  companyName: string;
  fullName: string;
  userName: string;
  token: any;
  userImage: string;

  isDetails: boolean = false;
  lastRoute: string;
  userId: string;

  isShowBlackBox: boolean = false;
  constructor(
    private sanitizer: DomSanitizer,
    private sharedService: SharedServiceService,
    private store: Store<RootState>,
    private _router: Router
  ) {
    this.sharedBlackBox();
    this.sharedService.showBB.subscribe((data) => {
      this.isShowChatHead = data.isShowChatHead;
      this.isShowChatBody = data.isShowChatBody;
    });
  }

  sharedBlackBox() {
    this.sharedService.getBlackBoxUrl.subscribe((url) => {
      this._router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          startWith(this._router)
        )
        .subscribe((event: NavigationEnd) => {
          this.getUserDetails();
          let url1 = event.url;
          let typeListing = this.validateListingFilter(url1);
          const tokenFormat = `companyName=${this.companyName}&companyId=${this.companyId}&fullName=${this.fullName}&userName=${this.userName}&userImage=${this.userImage}&token=${this.token}&isBookingParty=false&isAgency=false&listingType=${typeListing}&userId=${this.userId}&filter=true&serviceType=Sea Freight - Inbound,Sea Freight - Outbound`;
          if (
            url1.includes(
              "/main/transaction-management/service-request/service-ticket/"
            )
          ) {
            const formattedUrl = `${url}&${tokenFormat}`;
            this.isDetails = true;
            this.closeIframe();
            this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              `${environment.blackbox}/?${formattedUrl}`
            );
            this.access(url);
          } else {
            this.getUserDetails();
            this.closeIframe();
            this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              `${environment.blackbox}/?${tokenFormat}`
            );
          }
        });
    });
  }

  validateListingFilter(url1) {
    return "Service Level Channel,Service level Channel";
  }

  ngOnInit(): void {
    let _this = this;
    window.addEventListener("message", function (e) {
      // Get the sent data
      if (e.data?.message === "details") {
        const { serviceType, details, parentId, description, members, roleAs } =
          e.data;
        _this.validateDetails(
          serviceType,
          details,
          parentId,
          description,
          members,
          roleAs
        );
      }
      if (e.data?.message === "listing") {
        if (_this.lastRoute) {
          _this.getRouterUrl(_this.lastRoute);
        } else {
          _this.getRouterUrl("/main/transaction-management/");
        }
      }
    });
  }

  validateDetails(type, details, parentId, description, data, roleAs) {
    if (description.includes("Trip")) {
      let trip = details.split("-");
      this.getRouterUrl(
        `/main/transaction-management/booking-reservations/trip-management/${trip[0]}/${trip[1]}/0`
      );
    }
    if (description.includes("Service")) {
      this.getServiceUrl(type, details, parentId, data, roleAs);
    }

    if (description.includes("Reservation")) {
      this.getReservationUrl(type, details);
    }
  }

  getServiceUrl(type, details, parentId, member, role) {
    this.getRouterUrl(
      `/main/transaction-management/service-request/service-ticket/${parentId}`
    );
  }

  getReservationUrl(type, details) {
    localStorage.setItem("routeToReservation", "true");
    if (type === "Trucking") {
      this.getRouterUrl(
        `/main/transaction-management/booking-reservations/truckingdetails/${details}`
      );
    } else if (type === "Trucking Linehaul") {
      this.getRouterUrl(
        `/main/transaction-management/booking-reservations/details/mile/${details}`
      );
    } else {
      this.getRouterUrl(
        `/main/transaction-management/booking-reservations/details/${details}`
      );
    }
  }

  getRouterUrl(url) {
    this._router.navigate([url], { replaceUrl: true });
  }

  access(reservationId?: string) {
    let frame = document.getElementById("main_iframe") as HTMLIFrameElement;
  }

  minimizeIframe() {
    this.isShowChat = !this.isShowChat;
  }

  closeIframe() {
    let data = {
      isShowChatBody: false,
      isShowChatHead: true,
    };
    this.sharedService.setshowBB(data);
  }

  iconClick() {
    let data = {
      isShowChatBody: true,
      isShowChatHead: false,
    };
    this.sharedBlackBox();
    this.sharedService.setshowBB(data);
    this.sharedService.setChatSupport(false);
  }

  getUserDetails() {
    let token = JSON.parse(localStorage.getItem("token"));
    const {
      authenticationResult: { idToken },
    } = token && token.cognito;

    this.token = idToken;
    this.store
      .pipe(select(selectUser))
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          const {
            company: { name, companyId },
            user: { name: userDetails, username, image, userId },
          } = result;
          this.companyId = companyId;
          this.companyName = name;
          this.fullName = userDetails;
          this.userName = username;
          this.userImage = image;
          this.userId = userId;
        }
      });
  }
}

import { Action } from '@ngrx/store';
import { Booking } from 'app/core/models/booking.model';

export enum ServiceRequestActionTypes {
  SetServiceRequest = '[Service Request] Set Service Request',
  RemoveServiceRequest = '[Service Request] Remove Service Request',
}

export class SetServiceRequest implements Action {
  readonly type = ServiceRequestActionTypes.SetServiceRequest;
  constructor(public payload: Booking) {}
}

export class RemoveServiceRequest implements Action {
  readonly type = ServiceRequestActionTypes.RemoveServiceRequest;
  constructor() {}
}

export type ServiceRequestActions = SetServiceRequest | RemoveServiceRequest;

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PortService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Get All Ports
   *
   * @returns {Observable<any>}
   * @memberof PortService
   */
  getPortListing(): Observable<any> {
    return this.http.get<any>(`${environment.globalCMS}/global/cms/api/v1/port?pageSize=900`)
  }
  getPortFilterListing(filteredValue,pageSize): Observable<any>{
    const params = []
    
    for (const key in filteredValue) {
      if (filteredValue[key]) {
        params.push(`${key}:${filteredValue[key]}`)

      }
    }
    return this.http.get<any>(`${environment.globalAggregator}/g/api/v1/port/filter?query=` + params.join(',').replace(/,(?=\s*$)/, '') +`&pageSize=${pageSize}` );
  }

}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "environments/environment";
import { Store } from "@ngrx/store";
import { RootState } from "../core/store";
import { Observable } from "rxjs";
// import { SetCompany } from './core/store/company/company.action';
// import { SetUser } from './core/store/user/user.action';
// import { User } from './core/models/user.model';

@Injectable({
  providedIn: "root",
})
export class DataService {
  constructor(private http: HttpClient, private store: Store<RootState>) {}

  changePassword(body): Observable<any> {
    const token = JSON.parse(localStorage.getItem("token"));

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        AccessToken:
          token &&
          token.cognito &&
          token.cognito.authenticationResult &&
          token.cognito.authenticationResult.accessToken,
        Authorization:
          token &&
          token.cognito &&
          token.cognito.authenticationResult &&
          token.cognito.authenticationResult.idToken,
      }),
    };
    return this.http.patch<any>(
      `${environment.authManagementAuthentication}/api/v1/auth/change-password`,
      body,
      httpOptions
    );
  }

  portListing(filter, search): Observable<any> {
    let params = new HttpParams().set("query", `${filter}:${search}`);
    return this.http.get<any>(
      `${environment.globalAggregator}/g/api/v1/port/filter`,
      { params: params }
    );
  }

  // Vessel Lines

  lineListing(companyId, payload): Observable<any> {
    let params = new HttpParams()
      .set("pageNumber", payload.page)
      .set("pageSize", payload.pageSize);

    if (payload.query !== "") {
      params = params.set("query", payload.query);
    }

    if (payload.orderBy !== "") {
      params = params.set("orderBy", payload.orderBy);
    }
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/line/list/${companyId}`,
      { params: params }
    );
  }

  lineDetails(lineId): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/line/${lineId}`
    );
  }

  lineCreate(body): Observable<any> {
    const token = JSON.parse(localStorage.getItem("token"));

    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'AccessToken': token && token.cognito && token.cognito.authenticationResult && token.cognito.authenticationResult.accessToken,
    //   })
    // };
    return this.http.post<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/line`,
      body
    );
  }

  lineUpdate(body): Observable<any> {
    return this.http.put<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/line`,
      body
    );
  }

  lineDelete(lineId): Observable<any> {
    return this.http.delete<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/line/${lineId}`
    );
  }

  lineMultipleDelete(lineids): Observable<any> {
    let ids = new HttpParams().set("guids", lineids);
    return this.http.delete<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/line`,
      { params: ids }
    );
  }

  LineSearch(filter): Observable<any> {
    // const token = JSON.parse(localStorage.getItem('token'));
    const user = JSON.parse(localStorage.getItem("user"));

    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'AccessToken': token && token.cognito && token.cognito.authenticationResult && token.cognito.authenticationResult.accessToken,
    //   })
    // };
    let params = new HttpParams().set("search", filter);
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/line/company/${user.company.companyId}`,
      { params: params }
    );
  }

  // Vessel Lines

  // VESSELS

  vesselScheduleRotation(vesselId): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/vessel/${vesselId}`
    );
  }

  newvesselScheduleRotation(vesselId): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/voyage/vessel?vesselGuid=${vesselId}`
    );
  }

  vesselListing(companyId, payload): Observable<any> {
    let params = new HttpParams()
      .set("pageNumber", payload.pageNumber)
      .set("pageSize", payload.pageSize);

    if (payload.query !== "") {
      params = params.set("query", payload.query);
    }

    if (payload.orderBy !== "") {
      params = params.set("orderBy", payload.orderBy);
    }

    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/vessel/list/${companyId}`,
      { params: params }
    );
  }

  vesselDetails(vesselId): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/vessel/${vesselId}`
    );
  }

  vesselCreate(body): Observable<any> {
    return this.http.post<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/vessel`,
      body
    );
  }

  vesselUpdate(body): Observable<any> {
    return this.http.put<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/vessel`,
      body
    );
  }

  vesselDelete(vesselId): Observable<any> {
    return this.http.delete<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/vessel/${vesselId}`
    );
  }

  vesselMultipleDelete(vesselIds): Observable<any> {
    // const token = JSON.parse(localStorage.getItem('token'));

    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type':'application/json',
    //     'AccessToken': token && token.cognito && token.cognito.authenticationResult && token.cognito.authenticationResult.accessToken,
    //   })
    // };
    let ids = new HttpParams().set("guids", vesselIds);
    return this.http.delete<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/vessel`,
      { params: ids }
    );
  }

  vesselLineSearch(filter, lineId, companyId): Observable<any> {
    let params = new HttpParams().set("query", filter);
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/vessel/list/${companyId}/${lineId}?query=name:${filter}`
    );
  }

  vesselBulkUpload(companyId, file): Observable<any> {
    // const token = JSON.parse(localStorage.getItem('token'));
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //     'AccessToken': token && token.cognito && token.cognito.authenticationResult && token.cognito.authenticationResult.accessToken,
    //   })
    // };
    return this.http.post<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/vessel/upload?companyId=${companyId}`,
      file
    );
  }

  // VESSELS

  vesselSchedule(lineGuid, companyId, payload): Observable<any> {
    let params = new HttpParams()
      .set("lineGuid", lineGuid)
      .set("pageNumber", payload.page)
      .set("pageSize", payload.pageSize);

    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/voyage/list/${companyId}`,
      { params: params }
    );
  }

  vesselScheduleParam(lineGuid, companyId, payload): Observable<any> {
    let params = new HttpParams()
      .set("pageNumber", payload.page)
      .set("pageSize", payload.pageSize);
    params = params.append("lineGuid", lineGuid);

    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/voyage/list/${companyId}`,
      { params: params }
    );
  }

  CreateSchedule(body): Observable<any> {
    return this.http.post<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/schedule`,
      body
    );
  }

  updateSchedule(payload): Observable<any> {
    return this.http.put<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/schedule`,
      payload
    );
  }

  createVoyage(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/voyage`,
      payload
    );
  }

  updateVoyage(payload): Observable<any> {
    return this.http.put<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/voyage`,
      payload
    );
  }

  deleteVoyage(guid): Observable<any> {
    return this.http.delete<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/voyage/${guid}`
    );
  }

  scheduleVessel(vesselId, companyId, payload): Observable<any> {
    let params = new HttpParams()
      .set("pageNumber", payload.page)
      .set("pageSize", payload.pageSize);
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/voyage/list/${companyId}?vesselGuid=${vesselId}`,
      { params: params }
    );
  }

  getCSV(companyId, payload) {
    let params = new HttpParams()
      .set("pageNumber", payload.pageNumber || 0)
      .set("pageSize", payload.pageSize);

    if (payload.query !== "") {
      params = params.set("query", payload.query);
    }

    let headers = new HttpHeaders({
      Accept: "text/csv",
    });

    return this.http.get(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/vessel/file/csv/${companyId}`,
      {
        params: params,
        headers,
        responseType: "text" as "json",
      }
    );
  }
  getExcel(companyId, payload) {
    let params = new HttpParams()
      .set("pageNumber", payload.pageNumber)
      .set("pageSize", payload.pageSize);

    if (payload.query !== "") {
      params = params.set("query", payload.query);
    }
    return this.http.get(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/vessel/file/excel/${companyId}`,
      {
        params: params,
        responseType: "blob" as "json",
      }
    );
  }

  getCSVLines(companyId, payload) {
    // let headers = new HttpHeaders({
    //   Accept: "text/csv",
    // });

    // return this.http.get(
    //   `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/line/file/csv/${companyId}`,
    //   {
    //     headers,
    //     responseType: "text" as "json",
    //   }
    // );
    let params = new HttpParams()
      .set("pageNumber", payload.pageNumber || 0)
      .set("pageSize", payload.pageSize);

    if (payload.query !== "") {
      params = params.set("query", payload.query);
    }

    let headers = new HttpHeaders({
      Accept: "text/csv",
    });

    return this.http.get(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/line/file/csv/${companyId}`,
      {
        params: params,
        headers,
        responseType: "text" as "json",
      }
    );
  }
  getExcelLines(companyId, payload) {
    // return this.http.get(
    //   `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/line/file/excel/${companyId}`,
    //   {
    //     responseType: "blob" as "json",
    //   }
    // );
    let params = new HttpParams()
      .set("pageNumber", payload.pageNumber || 0)
      .set("pageSize", payload.pageSize);

    if (payload.query !== "") {
      params = params.set("query", payload.query);
    }
    return this.http.get(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/line/file/excel/${companyId}`,
      {
        params: params,
        responseType: "blob" as "json",
      }
    );
  }

  getLineAuditLogs(companyId, payload): Observable<any> {
    let params = new HttpParams()
      .set("pageNumber", payload.page)
      .set("pageSize", payload.pageSize)
      .set("module", payload?.module)
      .set("subModule", payload?.subModule);

    if (payload.query !== "") {
      params = params.set("query", payload.query);
    }

    if (payload.orderBy !== "") {
      params = params.set("orderBy", payload.orderBy);
    }

    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/audit-log/list/${companyId}`,
      { params: params }
    );
  }

  getCSVLinesAudit(companyId, payload, total, timezone) {
    let headers = new HttpHeaders({
      Accept: "text/csv",
    });

    let params = new HttpParams()
      .set("pageNumber", payload.page + 1)
      .set("pageSize", payload.pageSize)
      .set("module", payload?.module)
      .set("subModule", payload?.subModule)
      .set("timezoneOffset", timezone);
    
    if (payload.query !== "") {
      params = params.set("query", payload.query);
    }

    if (payload.orderBy !== "") {
      params = params.set("orderBy", payload.orderBy);
    }

    return this.http.get(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/audit-log/file/csv/${companyId}`,
      {
        headers,
        params: params,
        responseType: "text" as "json",
      }
    );
  }

  getExcelLinesAudit(companyId, payload, total, timezone) {
    let params = new HttpParams()
      .set("pageNumber", payload.page + 1)
      .set("pageSize", payload.pageSize)
      .set("module", payload?.module)
      .set("subModule", payload?.subModule)
      .set("timezoneOffset", timezone);

      if (payload.query !== "") {
        params = params.set("query", payload.query);
      }
  
      if (payload.orderBy !== "") {
        params = params.set("orderBy", payload.orderBy);
      }

    return this.http.get(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/audit-log/file/excel/${companyId}`,
      {
        params: params,
        responseType: "blob" as "json",
      }
    );
  }

  getVesselAuditlogsListing(companyId, payload): Observable<any> {
    let params = new HttpParams()
      .set("query", payload.query)
      .set("pageNumber", payload.page + 1)
      .set("pageSize", payload.pageSize)
      .set("orderBy", payload.orderBy)
      .set("module", "Vessel & Schedule")
      .set("subModule", "Vessel");

    if (payload.keyFieldId !== "") {
      params = params.set("keyFieldId", payload.keyFieldId);
    }

    return this.http.get(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/audit-log/list/${companyId}`,
      { params: params }
    );
  }

  getVesselAuditlogsExcel(companyId, payload): Observable<any> {
    let params = new HttpParams()
      .set("query", payload.query)
      .set("pageNumber", payload.page + 1)
      .set("pageSize", payload.pageSize)
      .set("orderBy", payload.orderBy)
      .set("module", "Vessel & Schedule")
      .set("subModule", "Vessel");

    if (payload.vesselGuid !== "") {
      params = params.set("vesselGuid", payload.vesselGuid);
    }
    if (payload.keyFieldId !== "") {
      params = params.set("keyFieldId", payload.keyFieldId);
    }

    return this.http.get(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/audit-log/file/excel/${companyId}`,
      {
        params: params,
        responseType: "blob" as "json",
      }
    );
  }

  getVesselAuditlogsCsv(companyId, payload): Observable<any> {
    let headers = new HttpHeaders({
      Accept: "text/csv",
    });

    let params = new HttpParams()
      .set("query", payload.query)
      .set("pageNumber", payload.page + 1)
      .set("pageSize", payload.pageSize)
      .set("orderBy", payload.orderBy)
      .set("module", "Vessel & Schedule")
      .set("subModule", "Vessel");

    if (payload.vesselGuid !== "") {
      params = params.set("vesselGuid", payload.vesselGuid);
    }
    if (payload.keyFieldId !== "") {
      params = params.set("keyFieldId", payload.keyFieldId);
    }

    return this.http.get(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/audit-log/file/csv/${companyId}`,
      {
        headers,
        params: params,
        responseType: "text" as "json",
      }
    );
  }

  getVesselAuditlogsDetails(guid): Observable<any> {
    return this.http.get(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/audit-log/${guid}`
    );
  }

  getLineArrivalNotice(companyId, portCodes, search) {
    let params = new HttpParams()
      .set("locodes", portCodes)
      .set("companyId", companyId);
    if (search) {
      params = params.append("search", search);
    }
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/line/port-locode`,
      { params: params }
    );
  }

  createVesselScheduleArrivalNotice(payload) {
    return this.http.post<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/schedule/arrival-notice`,
      payload
    );
  }

  getSearchVesselSchedule(query) {
    let params = new HttpParams();
    for (const key in query) {
      if (query[key]) {
        params = params.append(key, query[key]);
      }
    }
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/vessel-schedule/voyage/search-schedule`,
      { params: params }
    );
  }

  getPortDetailsById(portId) {
    return this.http.get<any>(
      `${environment.globalCMS}/global/cms/api/v1/port/${portId}`
    );
  }
}

import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  ActionReducer,
  MetaReducer,
} from '@ngrx/store';
import { storageSync } from '@larscom/ngrx-store-storagesync';
import * as fromUser from './user/user.reducer';
import * as fromCompany from './company/company.reducer';
import * as fromProfile from './profile/profile.reducer';
import * as fromPort from './port/port.reducer'
import * as fromMenuPermission from './menu-permission/menu-permission.reducer';
import * as fromServiceRequest from './service-request/service-request.reducer';
import * as fromAccreditationRequest from './accreditation/accreditation-request.reducer';
import * as fromContainerReducer from './container/container.reducer';

import { User } from '../models/user.model';
import { Company } from '../models/company.model';
import { Profile } from '../models/profile.model';
import { Port } from '../models/port.model';
import { Booking } from '../models/booking.model';
import * as fromBooking from './booking/booking.reducer';
import { MenuPermission } from '../models/menu-permission.model';
import { AccreditationRequest } from '../models/accreditation-request.model'
import { from } from 'rxjs';
import { ContainerList } from 'app/main/apps/container-management/component/container-management.type';

export interface RootState {
  user: User;
  company: Company;
  profile: Profile;
  port: Port[];
  booking: Booking;
  menuPermission: MenuPermission[];
  serviceRequest: any;
  accreditationRequest: AccreditationRequest;
  containerRequest: ContainerList;
}

export const reducers: ActionReducerMap<RootState> = {
  user: fromUser.reducer,
  company: fromCompany.reducer,
  profile: fromProfile.reducer,
  port: fromPort.reducer,
  booking: fromBooking.reducer,
  menuPermission: fromMenuPermission.reducer,
  serviceRequest: fromServiceRequest.reducer,
  accreditationRequest: fromAccreditationRequest.reducer,
  containerRequest: fromContainerReducer.reducer
};

export function storageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return storageSync<RootState>({
    features: [
      { stateKey: 'user' },
      { stateKey: 'profile' },
      { stateKey: 'company' },
      { stateKey: 'port' },
      { stateKey: 'booking' },
      { stateKey: 'menuPermission' },
      { stateKey: 'serviceRequest' },
      { stateKey: 'accreditationRequest' },
      { stateKey: 'containerRequest' }
    ],
    storage: window.localStorage,
  })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [storageSyncReducer];

// Users
export const selectUserState = createFeatureSelector<User>('user');

export const selectUser = createSelector(selectUserState, (state) => state);

// Company
export const selectCompanyState = createFeatureSelector<Company>('company');

export const selectCompany = createSelector(
  selectCompanyState,
  (state) => state
);

// Profile
export const selectProfileState = createFeatureSelector<Profile>('profile');

export const selectProfile = createSelector(
  selectProfileState,
  (state) => state
);

// Port
export const selectPortState = createFeatureSelector<Port[]>('port');

export const selectPort = createSelector(selectPortState, (state) => state);

// Booking
export const selectBookingState = createFeatureSelector<Booking>('booking');

export const selectBooking = createSelector(
  selectBookingState,
  (state) => state
);

// Menu Permission
export const selectMenuPermissionState = createFeatureSelector<MenuPermission[]>('menuPermission');

export const selectMenuPermission = createSelector(selectMenuPermissionState, (state) => state);


// Service Request
export const selectServiceRequest = createFeatureSelector<any>('serviceRequest');

export const selectRequest = createSelector(
  selectServiceRequest,
  (state) => state
);

//Accreditation Request
export const selectAccreditationRequestState = createFeatureSelector<AccreditationRequest>('accreditationRequest');

export const selectAccreditationRequest = createSelector(
  selectAccreditationRequestState,
  (state) => state
);

//Container Request
export const selectContainerRequestState = createFeatureSelector<ContainerList>('containerRequest');

export const selectContainerRequest = createSelector(
  selectContainerRequestState,
  (state) => state
);
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-info-notif',
  templateUrl: './info-notif.component.html',
  styleUrls: ['./info-notif.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class InfoNotifComponent implements OnInit {

  data: any;
  constructor(
    public matDialogRef: MatDialogRef<InfoNotifComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) {
    this.data = this._data;
   }

  ngOnInit(): void {
  }

  action(data){
    this.matDialogRef.close(data?.button?.action);
  }
}

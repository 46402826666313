import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatRippleModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FuseSharedModule } from "@fuse/shared.module";
import { NgxDropzoneModule } from "ngx-dropzone";
import { CommonModule } from "@angular/common";
import { KycNotificationComponent } from "./kyc-notification/kyc-notification.component";
import { RouterModule, Routes } from "@angular/router";
import { MultipleFileviewerComponent } from "./multiple-fileviewer/multiple-fileviewer.component";
import { ChatSuuportComponent } from "./chat-support/chat-support.component";
import { ProtectedImagePipe } from "app/core/pipe/protected-image/protected-image.pipe";
import { BlackboxComponentComponent } from "./blackbox-component/blackbox-component.component";

const routes: Routes = [
  {
    path: "",
    component: KycNotificationComponent,
  },
];

@NgModule({
  declarations: [
    KycNotificationComponent,
    MultipleFileviewerComponent,
    ChatSuuportComponent,
    BlackboxComponentComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FuseSharedModule,
    MatButtonModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatMenuModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatDividerModule,
    NgxDropzoneModule,
    MatRadioModule,
  ],
  exports: [
    KycNotificationComponent,
    ChatSuuportComponent,
    BlackboxComponentComponent,
  ],
  providers: [ProtectedImagePipe],
})
export class ReusableModule {}

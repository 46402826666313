import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploaderService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Uploads image to server
   *
   * @param {*} body
   * @returns {Observable<any>}
   * @memberof UploaderService
   */
  s3UploadImage(body: any): Observable<any> {
    // return this.http.post<any>(`${environment.globalCMS}/global/cms/api/v1/subMenuContent/upload`, body);
    return this.http.post<any>(`${environment.globalCMS}/global/cms/api/v1/subMenuContent/upload`, body);
  }

  /**
   * Uploads image to server Async
   *
   * @param {*} body
   * @returns {Observable<any>}
   * @memberof UploaderService
   */
  async asyncUpload(body: any) {
    return new Promise((resolve) => {
      this.http
        .post<any>(`${environment.globalCMS}/global/cms/api/v1/file/upload`, body)
        .subscribe((response) => {
          resolve(response.data)
        })
    })
  }

  singleImageUpload(body: any): Observable<any> {
    return this.http.post<any>(`${environment.globalCMS}/global/cms/api/v1/file/upload`, body);
  }

  multipleImageUpload(body: any): Observable<any> {
    return this.http.post<any>(`${environment.globalCMS}/global/cms/api/v1/file/upload-multiple`, body);
  }

  singleUploadTransaction(body): any {
    return this.http.post(`${environment.transactionManagement}/otm/api/v1/transactions/file/upload`, body);
  }

  multipleUploadTransaction(body): any {
    return this.http.post(`${environment.transactionManagement}/otm/api/v1/transactions/file/upload-multiple`, body);
  }

  getTransactionImage(payload): any {

    return this.http.get(`${environment.transactionManagement}/otm/api/v1/transactions/file/generate-presigned-url?path=${payload}`
    );
  }

  getClientImage(payload): any {
    return this.http.get(`${environment.globalCMS}/global/cms/api/v1/file/pre-signed?path=${payload}`)
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { FuseUtils } from "@fuse/utils";

@Injectable({
  providedIn: "root",
})
export class SharedServiceService {
  private dataDetails: BehaviorSubject<boolean>;
  private eventStatus: BehaviorSubject<boolean>;
  private _isChatSupport: BehaviorSubject<any | null> = new BehaviorSubject(
    false
  );
  private blackboxUrl: BehaviorSubject<any>;
  private isShowBB: BehaviorSubject<any | null> = new BehaviorSubject(null);
  private refreshing: BehaviorSubject<boolean>;

  serviceType;
  statusType;

  constructor() {
    this.dataDetails = new BehaviorSubject<boolean>(false);
    this.refreshing = new BehaviorSubject<boolean>(false);

    this.eventStatus = new BehaviorSubject<boolean>(false);
    this.blackboxUrl = new BehaviorSubject(false);
  }

  getValue(): Observable<boolean> {
    return this.dataDetails.asObservable();
  }
  setValue(newValue): void {
    this.dataDetails.next(newValue);
  }

  /**
   * Returns list of event status
   *
   * @param {number} event
   * @memberof BookingReservationListComponent
   */
  eventStatusList(event: number) {
    return FuseUtils.getCurrentEvent(event);
  }

  get chatSupport(): Observable<any> {
    return this._isChatSupport.asObservable();
  }

  setChatSupport(value) {
    this._isChatSupport.next(value);
  }

  get getBlackBoxUrl(): Observable<any> {
    return this.blackboxUrl.asObservable();
  }

  setBlackBoxUrl(value) {
    this.blackboxUrl.next(value);
  }

  get showBB(): Observable<any> {
    return this.isShowBB.asObservable();
  }

  setshowBB(value) {
    this.isShowBB.next(value);
  }

  setRefresh(value): void {
    this.refreshing.next(value);
  }

  get refresh(): Observable<boolean> {
    return this.refreshing.asObservable();
  }
}
